import BigNumber from 'bignumber.js';
import cloneDeep from 'lodash/cloneDeep';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';

import { formatMoney } from '@mablemarket/common-lib';
import { CartDisplay, CartItem, CartItemInput, CartOrder, CartOrderBuyerNote, CartSeller, CartSellerGroup, Checkout, Discount, ProductVariantDisplayInfo, SellerDisplayInfo } from '@mablemarket/core-api-client';

export enum CheckoutVersions {
  original = 1,
  collectCardAtCheckout = 2,
  buyerLocations = 3,
}

export const cartDescription = (cart: CartDisplay) => {
  const result = [
    ...cart.sellerGroups.map((group) => {
      return [
        `${group.seller.name} - ${group.subtotalDisplay} (${group.subtotal})`,
        `  Order minimum: ${group.seller.orderMinimum ? group.seller.orderMinimum.toFixed(2) : 'none'}`,
        `  Order Minimum Met: ${group.orderMinimumUnmet ? 'false' : 'true'}`,
        `  Note: ${group.buyerNote ?? '(none)'}`,
        '  Items:',
        ...group.cartItems.map((cartItem) => {
          return [
            `  - ${cartItem.quantity} ${cartItem.variant.name}`,
            `${cartItem.variant.price ? `@ ${formatMoney(cartItem.variant.price)}` : ''}`,
            `(${cartItem.subtotalDisplay})`,
            `vid: ${cartItem.variant.variantId}, sid: ${cartItem.variant.sellerId}`,
          ].join(' ');
        }),
      ].join('\n');
    }),
    `Subtotal: ${cart.subtotal.toFixed(2)}`,
    `Subtotal Display: ${cart.subtotalDisplay}`,
    `Discounts: ${cart.discounts.map(d => `${d.name} (${d.amount})`).join(', ')}`,
  ].filter(s => s).join('\n');
  return result;
};

export const makeEmptyCart = (): CartDisplay => {
  return {
    sellerGroups: [] as CartSellerGroup[],
    subtotal: new BigNumber(0),
    subtotalDisplay: '',
    shippingCostDisplay: 'TBD',
    checkoutEnabled: false,
    discounts: [] as Discount[],
  };
};

export const cartOrderFromCheckout = (data: {
  checkout: Checkout;
  cart: CartDisplay;
}): CartOrder => {
  const {
    checkout,
    cart,
  } = data;

  const buyerNotes: CartOrderBuyerNote[] = cart.sellerGroups
    .filter(group => group.buyerNote)
    .map((group) => {
      return {
        sellerId: group.seller.id,
        // The filter above ensure buyerNote is defined
        buyerNote: group.buyerNote ?? '',
      };
    });

  return {
    idempotencyId: checkout.idempotencyId,
    buyerId: checkout.buyerId,
    items: flatMap(cart.sellerGroups, (group) => {
      const cartItems: CartItem[] = group.cartItems.map((ci) => {
        return {
          productVariantId: ci.variant.variantId,
          quantity: ci.quantity,
        };
      });
      return cartItems;
    }),
    buyerNotes,
    discountCodes: cart.discounts.map(d => d.code),
    shipToAddressId: checkout.shipToAddressId,
    buyerLocationId: checkout.buyerLocationId,
  };
};

export const totalCaseQuantity = (cart: CartDisplay | undefined) => {
  if (!cart) {
    return 0;
  }
  return cart.sellerGroups.reduce((res: number, group) => {
    return res + group.cartItems.reduce((itemsRes: number, item) => {
      return itemsRes + item.quantity;
    }, 0);
  }, 0);
};

export const cartItems = (cart: CartDisplay) => {
  return flatMap(cart.sellerGroups, g => g.cartItems);
};

// `CartItemInput` with some extra info that makes updating local cache possible
export interface CartItemInputWithInfo {
  input: CartItemInput;
  variant: ProductVariantDisplayInfo;
  cartSeller?: CartSeller;
}

export const updatedCartBuyerNote = (opts: {
  cart: CartDisplay;
  cartSeller: CartSeller;
  note: string | undefined;
  action: 'update' | 'append' | 'remove';
}) => {
  const {
    cart,
    cartSeller,
    note,
    action,
  } = opts;

  const res = { ...cart };

  const existingSellerGroup = res.sellerGroups.find(g => g.seller.id === cartSeller.id);
  const finalNote = (existingSellerGroup && existingSellerGroup.buyerNote && action === 'append')
    ? `${existingSellerGroup.buyerNote}\n${note}`
    : note;

  if (existingSellerGroup) {
    existingSellerGroup.buyerNote = finalNote;
    if (existingSellerGroup.cartItems.length === 0) {
      // Nothing left!
      res.sellerGroups = res.sellerGroups.filter(g => g.seller.id !== existingSellerGroup.seller.id);
    }
  } else {
    res.sellerGroups.push({
      cartItems: [],
      seller: cartSeller,
      buyerNote: finalNote,
      subtotal: new BigNumber(0),
      subtotalDisplay: '',
      orderMinimumUnmet: true, // Can't check out with no items ¯\_(ツ)_/¯
    });
  }

  return res;
};

export const getCartSubtotal = (opts: {
  sellerGroups: CartSellerGroup[];
}) => {
  const { sellerGroups } = opts;

  return sellerGroups.reduce((result: BigNumber, sellerGroup) => {
    return result.plus(sellerGroup.subtotal);
  }, new BigNumber(0));
};

const subtotalForVariant = (opts: {
  variant: ProductVariantDisplayInfo;
  quantity: number;
}): [BigNumber, string] => {
  const { variant, quantity } = opts;
  const subtotal = variant.price
    ? (new BigNumber(quantity)).times(new BigNumber(variant.price))
    : new BigNumber(0);

  const subtotalDisplay = variant.price
    ? formatMoney(subtotal)
    : '';
  return [subtotal, subtotalDisplay];
};

export interface SubtotalInfo {
  containsPricelessItem: boolean;
  subtotal: BigNumber;
  subtotalDisplay: string;
  orderMinimumMet: boolean;
  freeShippingMinimumMet: boolean;
  shippingCostDisplay: string | undefined;
}

type SubtotalableCartItem = {
  quantity: number;
  variant?: {
    price?: string | undefined;
    sellerId: number;
  };
};

export const getSubtotalInfoForCartItems = (
  cartItemsInput: SubtotalableCartItem[],
  seller: Pick<SellerDisplayInfo, 'id' | 'orderMinimum' | 'freeShippingMinimum' | 'shippingPolicy'>,
): SubtotalInfo => {
  const cartItems = cartItemsInput.filter(ci => ci.variant?.sellerId === seller.id);

  const containsPricelessItem = cartItems.some(ci => ci.variant !== undefined && ci.variant.price === undefined);

  const subtotal = cartItems.reduce((result: BigNumber, cartItem) => {
    if (!cartItem.variant?.price) {
      return result;
    }
    const priceBigNum = new BigNumber(cartItem.variant.price);
    const itemSubtotal = priceBigNum.times(new BigNumber(cartItem.quantity));
    return result.plus(itemSubtotal);
  }, new BigNumber(0));

  const subtotalDisplay = containsPricelessItem
    ? ''
    : formatMoney(subtotal);

  const orderMinimumMet = seller.orderMinimum
    ? subtotal.gte(seller.orderMinimum)
    : cartItems.length > 0;

  const freeShippingMinimumMet =
    seller.shippingPolicy === 'sellerMinimum'
    && seller.freeShippingMinimum !== undefined
    && subtotal.isGreaterThanOrEqualTo(seller.freeShippingMinimum);
  const shippingCostDisplay = (() => {
    if (!orderMinimumMet || seller.shippingPolicy === 'none') {
      return undefined;
    }

    if (seller.shippingPolicy === 'free' || freeShippingMinimumMet) {
      return 'FREE';
    }

    // shippingPolicy === 'capped' or sellerMinimum not met
    return 'TBD';
  })();

  return {
    subtotal,
    subtotalDisplay,
    containsPricelessItem,
    orderMinimumMet,
    freeShippingMinimumMet,
    shippingCostDisplay,
  };
};


export interface LocalCartUpdateResult {
  cart: CartDisplay;
  missingSellerInfo: boolean;
}

export const getCheckoutEnabled = (sellerGroups: CartSellerGroup[]) => {
  return sellerGroups.some((g) => {
    return !g.orderMinimumUnmet && !isEmpty(g.cartItems);
  });
};

export const updatedCartWithItemInputs = (opts: {
  cart: CartDisplay;
  inputInfos: CartItemInputWithInfo[];
}): LocalCartUpdateResult => {
  const {
    cart,
    inputInfos,
  } = opts;

  const res = cloneDeep(cart);
  let missingSellerInfo = false;
  inputInfos.forEach((inputInfo) => {
    const { variant } = inputInfo;

    const existingSellerGroup = res.sellerGroups.find(g => g.seller.id === variant.sellerId);

    // Add a new cart seller group if there is none
    if (!existingSellerGroup) {
      missingSellerInfo = !inputInfo.cartSeller;
      const cartSeller: CartSeller = inputInfo.cartSeller ?? {
        id: variant.sellerId,
        slug: variant.sellerSlug,
        name: variant.sellerName,
        cold: false,
        shippingPolicy: variant.shippingPolicy,
      };
      if (inputInfo.input.quantity < 1) {
        // Nothing to do
        return;
      }
      const [subtotal, subtotalDisplay] = subtotalForVariant({
        variant: inputInfo.variant,
        quantity: inputInfo.input.quantity,
      });
      const orderMinimumUnmet = cartSeller.orderMinimum
        ? cartSeller.orderMinimum.isGreaterThan(subtotal)
        : false;
      res.sellerGroups.push({
        cartItems: [
          {
            quantity: inputInfo.input.quantity,
            variant: inputInfo.variant,
            subtotalDisplay,
          },
        ],
        seller: cartSeller,
        subtotal,
        subtotalDisplay,
        orderMinimumUnmet,
      });

      // Sort the seller groups since we added a new one
      res.sellerGroups = res.sellerGroups.sort((g1, g2) => g1.seller.name.localeCompare(g2.seller.name));
      return;
    }

    // If there is an existing group, modify it
    const existingCartItem = existingSellerGroup.cartItems.find((ci) => {
      return ci.variant.variantId === variant.variantId;
    });
    const newQuantity = inputInfo.input.quantityTactic === 'absolute'
      ? inputInfo.input.quantity
      : inputInfo.input.quantity + (existingCartItem?.quantity ?? 0);
    const [, subtotalDisplay] = subtotalForVariant({
      variant: inputInfo.variant,
      quantity: newQuantity,
    });

    // If existing item, update it
    if (existingCartItem) {
      if (newQuantity < 1) {
        // Remove item
        existingSellerGroup.cartItems = existingSellerGroup.cartItems.filter((ci) => {
          return ci.variant.variantId !== variant.variantId;
        });

        if (existingSellerGroup.cartItems.length === 0 && !existingSellerGroup.buyerNote) {
          // Nothing left in this seller; remove it
          res.sellerGroups = res.sellerGroups.filter(g => g.seller.id !== existingSellerGroup.seller.id);
        }
      } else {
        // Update quantity & subtotal
        existingCartItem.quantity = newQuantity;
        existingCartItem.subtotalDisplay = subtotalDisplay;
      }
      return;
    }

    // Finally, new item; add it if qty is non-zero
    if (newQuantity > 0) {
      existingSellerGroup.cartItems.push({
        quantity: newQuantity,
        variant: inputInfo.variant,
        subtotalDisplay,
      });
      // Since we added something new, sort the items alphabetically
      existingSellerGroup.cartItems = existingSellerGroup.cartItems.sort((ci1, ci2) => {
        return ci1.variant.name.localeCompare(ci2.variant.name);
      });
    }
  });

  // Re-calculate the subtotals. Groups first, then cart.
  res.sellerGroups.forEach((g) => {
    const {
      subtotal,
      subtotalDisplay,
      orderMinimumMet,
    } = getSubtotalInfoForCartItems(g.cartItems, g.seller);

    g.subtotal = subtotal;
    g.subtotalDisplay = subtotalDisplay;
    g.orderMinimumUnmet = !orderMinimumMet;
  });

  res.subtotal = getCartSubtotal({ sellerGroups: res.sellerGroups });
  res.subtotalDisplay = formatMoney(res.subtotal);
  res.checkoutEnabled = getCheckoutEnabled(res.sellerGroups);

  return {
    cart: res,
    missingSellerInfo,
  };
};

export const getCartItem = (opts: {
  cart: CartDisplay;
  variantId: number;
}) => {
  const {
    cart,
    variantId,
  } = opts;

  const allItems = cartItems(cart);
  return allItems.find(ci => ci.variant.variantId === variantId);
};
